define("discourse/plugins/discourse-gamification/admin/components/admin-create-leaderboard", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _object, _computed, _service, _truthHelpers, _dButton, _ajax, _ajaxError, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "toasts", [_service.inject]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "newLeaderboardName", [_tracking.tracked], function () {
      return "";
    }))();
    #newLeaderboardName = (() => (dt7948.i(this, "newLeaderboardName"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "nameValid", [(0, _computed.and)("newLeaderboardName")]))();
    #nameValid = (() => (dt7948.i(this, "nameValid"), void 0))();
    createNewLeaderboard() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data1 = {
        name: this.newLeaderboardName,
        created_by_id: this.currentUser.id
      };
      return (0, _ajax.ajax)("/admin/plugins/gamification/leaderboard", {
        data: data1,
        type: "POST"
      }).then(leaderboard1 => {
        this.toasts.success({
          duration: 3000,
          data: {
            message: (0, _i18n.default)("gamification.leaderboard.create_success")
          }
        });
        this.router.transitionTo("adminPlugins.show.discourse-gamification-leaderboards.show", leaderboard1.id);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "createNewLeaderboard", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="new-leaderboard-container">
          <Input
            @type="text"
            class="new-leaderboard__name"
            @value={{this.newLeaderboardName}}
            placeholder={{i18n "gamification.leaderboard.name_placeholder"}}
          />
          <DButton
            @label="gamification.create"
            @title="gamification.create"
            class="btn-primary new-leaderboard__create"
            @disabled={{not this.nameValid}}
            @action={{this.createNewLeaderboard}}
          />
          <DButton
            class="new-leaderboard__cancel"
            @label="gamification.cancel"
            @title="gamification.cancel"
            @action={{@onCancel}}
          />
        </div>
      
    */
    {
      "id": "d42cJlpN",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"new-leaderboard-container\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"new-leaderboard__name\"],[16,\"placeholder\",[28,[32,1],[\"gamification.leaderboard.name_placeholder\"],null]]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"newLeaderboardName\"]]]],null],[1,\"\\n      \"],[8,[32,2],[[24,0,\"btn-primary new-leaderboard__create\"]],[[\"@label\",\"@title\",\"@disabled\",\"@action\"],[\"gamification.create\",\"gamification.create\",[28,[32,3],[[30,0,[\"nameValid\"]]],null],[30,0,[\"createNewLeaderboard\"]]]],null],[1,\"\\n      \"],[8,[32,2],[[24,0,\"new-leaderboard__cancel\"]],[[\"@label\",\"@title\",\"@action\"],[\"gamification.cancel\",\"gamification.cancel\",[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@onCancel\"],false,[]]",
      "moduleName": "/root/prime/srv/discourse/app/app/assets/javascripts/discourse/discourse/plugins/discourse-gamification/admin/components/admin-create-leaderboard.js",
      "scope": () => [_component2.Input, _i18n.default, _dButton.default, _truthHelpers.not],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = _default;
});